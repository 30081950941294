<template>
  <div class="be-form-custom-select-dropdown-search">
    <i class="be-form-custom-select-dropdown-search-icon fal fa-search fa-fw" />

    <input
      :value="modelValue"
      class="be-form-custom-select-dropdown-search-input"
      type="text"
      :placeholder="placeholder"
      @input="onInput"
      @keydown="onKeydown"
    />

    <be-spinner
      v-if="isSearching"
      class="be-form-custom-select-dropdown-search-spinner"
    />
  </div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },

  inject: [
    "asyncSearch",
    "asyncSearchDebounce",
    "isAsyncSearching",
    "searchPlaceholder",
  ],

  emits: ["input", "keydown", "update:modelValue"],

  data() {
    return {
      debounceSearch: null,
    };
  },

  computed: {
    isSearching() {
      return this.isAsyncSearching;
    },

    placeholder() {
      return (
        this.searchPlaceholder ||
        this.$t("components.shared.be_form_select.search_placeholder")
      );
    },
  },

  created() {
    this.debounceSearch = debounce(
      (value) => {
        this.$emit("update:modelValue", value);
        this.$emit("input", value);
      },
      Number(this.asyncSearchDebounce) || 300
    );
  },

  methods: {
    onInput(event) {
      if (this.asyncSearch) {
        this.debounceSearch(event.target.value);
      } else {
        this.$emit("update:modelValue", event.target.value);
        this.$emit("input", event.target.value);
      }
    },

    onKeydown(event) {
      this.$emit("keydown", event);
    },
  },
};
</script>
