<template>
  <div>
    <be-alert v-if="showLimitReachedMessage" variant="danger">
      {{ localMembership.errors["user_limit_reached"] }}
    </be-alert>

    <template v-if="showSuggestions && userNameSuggestions.length > 0">
      <div class="form-row">
        <div class="col-12 col-lg-6">
          <be-form-group
            label-for="suggestion"
            :label="$t('components.memberships.form.fetch_suggestion')"
          >
            <be-form-select
              id="suggestion"
              v-model="selectedUserSuggestion"
              :options="userNameSuggestions"
              option-label="name"
              option-value="id"
            >
              <template
                v-for="slotName in ['option', 'selected']"
                #[slotName]="{ option }"
                :key="slotName"
              >
                <div
                  v-if="option.name"
                  class="d-flex flex-wrap align-items-center gap-1"
                >
                  {{ option.name }}
                  <small class="text-muted">
                    ({{ formatSynaSuggestionOptionSubtext(option) }})
                  </small>
                </div>
              </template>
            </be-form-select>
          </be-form-group>
        </div>
      </div>

      <hr class="border-dashed mx-n3" />
    </template>

    <div class="form-row">
      <div class="col-12 col-lg-6">
        <be-form-group
          label-for="membership-user-name"
          :label="translateAttribute('user', 'name')"
          :error="getErrors(localMembership.user, 'name')"
        >
          <be-form-input
            id="membership-user-name"
            v-model="localMembership.user.name"
            :disabled="!localMembership.policy.update_user"
            required
            @change="
              clearSuggestion() && clearErrors(localMembership.user, 'name')
            "
          />
        </be-form-group>
      </div>

      <div class="col-12 col-lg-6">
        <be-form-group
          label-for="membership-user-email"
          :label="translateAttribute('user', 'email')"
          :error="emailErrors"
        >
          <be-form-input
            id="membership-user-email"
            v-model="localMembership.user.email"
            :state="emailState"
            :disabled="!localMembership.policy.update_user"
            required
            @change="clearEmailErrors"
          />
        </be-form-group>
      </div>

      <div v-if="showIdentificationNumber" class="col-12 col-lg-6">
        <be-form-group
          label-for="membership-user-identification_number"
          :label="translateAttribute('user', 'identification_number')"
          :error="getErrors(localMembership.user, 'identification_number')"
          :description="$t('models.user.hints.identification_number')"
          required
        >
          <be-form-input
            id="membership-user-identification_number"
            v-model="localMembership.user.identification_number"
            :disabled="!localMembership.policy.update_user"
            :formatter="formatIdentificationNumber"
            required
            inputmode="numeric"
            trim
            @change="clearErrors(localMembership.user, 'identification_number')"
          />
        </be-form-group>
      </div>

      <div class="col-12 col-lg-6">
        <be-form-group
          label-for="membership-user-phone"
          :label="translateAttribute('user', 'phone')"
          :error="getErrors(localMembership.user, 'phone')"
        >
          <be-tel-input
            id="membership-user-phone"
            v-model="localMembership.user.phone"
            :disabled="!localMembership.policy.update_user"
            @change="clearErrors(localMembership.user, 'phone')"
          />
        </be-form-group>
      </div>

      <div class="col-12 col-lg-6">
        <locale-input
          id="membership-user-locale"
          :value="localMembership.user.locale"
          :label="translateAttribute('user', 'locale')"
          :state="validationState(localMembership.user, 'locale')"
          :error="getErrors(localMembership.user, 'locale')"
          :disabled="!localMembership.policy.update_user"
          required
          @input="localMembership.user.locale = $event"
          @change="clearErrors(localMembership.user, 'locale')"
        ></locale-input>
      </div>
    </div>

    <be-alert v-if="!localMembership.policy.update_user" variant="info">
      {{ $t("components.memberships.edit.only_editable_by_user") }}
    </be-alert>

    <hr class="border-dashed mx-n3" />

    <div class="form-row">
      <template v-if="hasUserAdministration">
        <div class="col-12 col-lg-6">
          <be-form-group
            label-for="membership-role"
            :label="translateAttribute('membership', 'role')"
            :error="getErrors(localMembership, 'role')"
          >
            <be-form-select
              id="membership-role"
              v-model="localMembership.role"
              :state="validationState(localMembership, 'role')"
              :options="roleOptions"
              include-blank-option
              @change="clearErrors(localMembership, 'role')"
            />
          </be-form-group>
        </div>

        <div class="col-12 col-lg-6">
          <be-form-group
            label-for="membership-function"
            :label="translateAttribute('membership', 'function')"
            :error="getErrors(localMembership, 'function')"
            required
          >
            <be-form-select
              id="membership-function"
              v-model="localMembership.function"
              :state="validationState(localMembership, 'function')"
              :options="functionOptions"
              required
              @change="clearErrors(localMembership, 'function')"
            />
          </be-form-group>
        </div>

        <div class="col-12 col-lg-6">
          <be-form-group
            label-for="membership-title"
            :label="translateAttribute('membership', 'title')"
            :error="getErrors(localMembership, 'title')"
          >
            <be-form-input
              id="membership-title"
              v-model="localMembership.title"
              @change="clearErrors(localMembership, 'title')"
            />
          </be-form-group>
        </div>

        <div class="col-12">
          <be-form-group
            label-for="membership-policy-level"
            :label="translateAttribute('membership', 'policy_level')"
            :invalid-feedback="getErrors(localMembership, 'policy_level')"
            required
          >
            <be-form-radio-group
              id="membership-policy-level"
              v-model="localMembership.policy_level"
              v-be-tooltip="{
                title: $t(
                  'components.memberships.form.policy_levels.last_user_warning'
                ),
                disabled: !lastAdminUser,
              }"
              :state="validationState(localMembership, 'policy_level')"
              :options="policyLevelOptions"
              :disabled="lastAdminUser"
              class="d-inline-block"
              required
              @change="clearErrors(localMembership, 'policy_level')"
            />
          </be-form-group>

          <be-alert
            variant="warning"
            :show="
              localMembership.policy_level === 'limited' &&
              localMembership.function != 'external'
            "
          >
            <!-- eslint-disable vue/no-v-html -->
            <span
              v-html="
                $t('components.memberships.form.policy_levels.warning_html')
              "
            />
          </be-alert>

          <be-alert variant="info" show>
            <span
              v-html="policyInformation(localMembership.policy_level)"
            ></span>
            <!-- eslint-enable vue/no-v-html -->
          </be-alert>
        </div>

        <div class="col-12 col-lg-6">
          <be-form-checkbox
            v-if="!!$currentCompany.has_admin_panels"
            id="membership-authorized-signatory"
            v-model="localMembership.authorized_signatory"
            class="mb-4"
            :state="validationState(localMembership, 'authorized_signatory')"
            @change="clearErrors(localMembership, 'authorized_signatory')"
          >
            {{ translateAttribute("membership", "authorized_signatory") }}
          </be-form-checkbox>

          <be-form-checkbox
            id="membership-is-temporary2"
            v-model="localMembership.is_temporary"
            class="mb-4"
            name="membership-is-temporary"
            :description="$t('models.membership.hints.is_temporary')"
          >
            {{ translateAttribute("membership", "is_temporary") }}
          </be-form-checkbox>

          <be-form-group
            v-if="localMembership.is_temporary"
            label-for="membership-expires-at"
            :label="translateAttribute('membership', 'expires_at')"
            :error="getErrors(localMembership, 'expires_at')"
            class="mx-2 pl-4"
            required
          >
            <be-form-datepicker
              id="membership-expires-at"
              v-model="localMembership.expires_at"
              name="membership-expires-at"
              :min-date="new Date()"
              required
              inline
            />
          </be-form-group>

          <be-form-checkbox
            id="membership-primary-contact"
            v-model="localMembership.primary_contact"
            name="membership-primary-contact"
            :description="$t('models.membership.hints.primary_contact')"
          >
            {{ translateAttribute("membership", "primary_contact") }}
          </be-form-checkbox>
        </div>
      </template>

      <div
        v-if="hasOriginOptions && localMembership.policy_level == 'observer'"
        class="col-12 col-lg-6"
      >
        <be-form-checkbox
          id="limit-origins-option"
          v-model="localMembership.limit_origins"
          name="limit-origins"
          :description="$t('components.memberships.form.hints.limit_origins')"
        >
          {{ $t("components.memberships.form.limit_origins") }}
        </be-form-checkbox>

        <be-form-group
          :label="$t('components.memberships.form.allowed_origins')"
          class="mt-2"
        >
          <be-form-select
            v-model="localMembership.origins"
            :options="originOptions"
            :disabled="!localMembership.limit_origins"
            multiple
          />
        </be-form-group>
      </div>
    </div>

    <div class="mt-4 d-flex gap-2 flex-wrap justify-content-end">
      <be-button variant="light" @click="cancel">
        {{ $t("buttons.titles.cancel") }}
      </be-button>

      <be-button
        variant="primary"
        :loading="loading"
        :disabled="loading"
        @click="submitForm"
      >
        {{ localButtonText }}
      </be-button>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

import LocaleInput from "@/components/shared/LocaleInput.vue";
import MembershipMixin from "@/mixins/memberships";

import formatIdentificationNumber from "@/utils/format-identification-number";

const { mapGetters: mapMembershipGetters } =
  createNamespacedHelpers("memberships");
const { mapGetters: mapSynaGetters, mapActions: mapSynaActions } =
  createNamespacedHelpers("syna");

export default {
  components: {
    LocaleInput,
  },

  mixins: [MembershipMixin],

  inject: ["originOptions"],

  props: {
    membership: {
      type: Object,
      required: true,
    },

    buttonText: {
      type: String,
      required: false,
      default: undefined, // $t("buttons.titles.save")
    },
  },

  emits: ["cancel", "submit"],

  data() {
    const localMembership = this.cloneDeep(this.membership);
    localMembership.limit_origins = !!localMembership.origins;

    return {
      localMembership,
      selectedUserSuggestion: "",
    };
  },

  computed: {
    hasUserAdministration() {
      return this.$platform.features.user_administration;
    },

    hasOriginOptions() {
      return this.originOptions.length > 0;
    },

    ...mapMembershipGetters(["invitations", "loading"]),
    ...mapMembershipGetters({ memberships: "getMemberships" }),
    ...mapSynaGetters(["getUserSuggestionsForCompany"]),

    localButtonText() {
      if (this.buttonText === undefined) {
        return this.$t("buttons.titles.save");
      }

      return this.buttonText;
    },

    showSuggestions() {
      return (
        this.platformEnabled("syna_suggestions") &&
        !this.localMembership.user.id &&
        !!this.$currentCompany.organization_number
      );
    },

    existingNames() {
      return this.memberships.map((membership) => membership.user.name);
    },

    userNameSuggestions() {
      if (!this.localMembership.policy.update_user) {
        return [];
      }

      const suggestions = this.getUserSuggestionsForCompany(
        this.$currentCompany.id
      );

      if (!suggestions) {
        return [];
      }
      return suggestions.filter(
        ({ name }) => !this.existingNames.includes(name)
      );
    },

    lastAdminUser() {
      if (!this.membership.id || this.membership.policy_level !== "admin") {
        return false;
      }

      const admins = this.memberships.filter(
        (membership) => membership.policy_level === "admin"
      );

      if (admins.length > 1) {
        return false;
      }

      return true;
    },

    showIdentificationNumber() {
      return !this.platformEnabled("email_authentication");
    },

    showLimitReachedMessage() {
      return (
        this.localMembership.errors &&
        this.localMembership.errors["user_limit_reached"] &&
        this.localMembership.errors["user_limit_reached"].length > 0
      );
    },

    emailErrors() {
      if (this.localMembership.user.errors?.email) {
        return this.localMembership.user.errors.email;
      }

      if (this.localMembership.errors?.company_id) {
        return this.localMembership.errors.company_id;
      }

      return null;
    },

    emailState() {
      const email = this.validationState(this.localMembership.user, "email");
      const company_id = this.validationState(
        this.localMembership,
        "company_id"
      );

      if (email == null && company_id == null) {
        return null;
      }

      if (email == null) {
        return company_id;
      }

      if (company_id == null) {
        return email;
      }

      return null;
    },
  },

  watch: {
    membership: {
      handler(new_value) {
        this.localMembership = this.cloneDeep(new_value);
      },

      deep: true,
    },

    "localMembership.policy_level": {
      handler(new_value) {
        if (new_value === "observer" && this.membership.origins !== null) {
          this.localMembership.limit_origins = true;
        } else {
          this.localMembership.limit_origins = false;
        }
      },
    },

    "localMembership.limit_origins": {
      handler(new_value) {
        if (!new_value) {
          this.localMembership.origins = null;
        } else if (this.membership.origins) {
          this.localMembership.origins = this.membership.origins;
        } else {
          this.localMembership.origins = [];
        }
      },
    },

    selectedUserSuggestion(value) {
      const user = this.userNameSuggestions.find(
        (suggestion) => suggestion.id === value
      );

      if (!user) {
        return;
      }

      this.localMembership = {
        ...this.localMembership,

        user: {
          ...this.localMembership.user,
          name: user.name,
        },

        authorized_signatory: user.authorized_signatory,
        function: user.func,
        policy_level: user.policy_level,
        role: user.role,
      };
    },
  },

  mounted() {
    if (this.showSuggestions) {
      this.loadUserSuggestions(this.$currentCompany.id);
    }
  },

  methods: {
    ...mapSynaActions(["loadUserSuggestions"]),

    formatIdentificationNumber,

    formatSynaSuggestionOptionSubtext(option) {
      const func = option.func
        ? this.$t(`models.membership.functions.${option.func}`)
        : null;
      const role = option.role
        ? this.$t(`models.membership.roles.${option.role}`)
        : null;

      return [func, role].filter(Boolean).join(", ");
    },

    clearSuggestion() {
      this.selectedUserSuggestion = "";
    },

    async validateMembership(membership) {
      // Check duplicate emails against unsaved invitations (memberships)
      const emailAlreadyExists = this.invitations.some((invitation) => {
        return (
          invitation.uuid != membership.uuid &&
          invitation.user.email == membership.user.email
        );
      });

      if (emailAlreadyExists) {
        this.localMembership.user.errors = {
          email: this.$i18n.t("models.membership.errors.already_exists"),
        };

        return false;
      }

      if (this.showIdentificationNumber) {
        const identificationNumberAlreadyExists = this.invitations.some(
          (invitation) => {
            return (
              invitation.uuid != membership.uuid &&
              invitation.user.identification_number ==
                membership.user.identification_number
            );
          }
        );

        if (identificationNumberAlreadyExists) {
          this.localMembership.user.errors = {
            identification_number: this.$i18n.t(
              "models.membership.errors.already_exists"
            ),
          };

          return false;
        }
      }

      // Check validations against already saved memberships
      try {
        let data = await this.$store.dispatch(
          "memberships/validateMembership",
          membership,
          membership.user
        );

        this.localMembership.errors = data.errors;
        this.localMembership.user.errors = data.user?.errors || {};

        return true;
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.localMembership.errors = error.response.data.errors;
          this.localMembership.user.errors =
            error.response.data.user?.errors || {};
        } else {
          this.handleError(error);
        }

        return false;
      }
    },

    cancel() {
      this.$emit("cancel");
      this.localMembership = this.cloneDeep(this.membership);
    },

    async submitForm(event) {
      event.preventDefault();
      if (this.localMembership.id) {
        this.$emit("submit", this.localMembership);
      } else {
        const status = await this.validateMembership(this.localMembership);
        if (status) {
          this.$emit("submit", this.localMembership);
        }
      }
    },

    clearEmailErrors() {
      if (this.localMembership.user.errors?.email) {
        this.localMembership.user.errors.email = null;
      }

      if (this.localMembership.errors?.company_id) {
        this.localMembership.errors.company_id = null;
      }
    },
  },
};
</script>
